import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Helmet from "react-helmet"

import Header from "./header"
import Footer from "./footer"

import favicon from "/src/static/favicon.ico"


const Layout = ({ children }) => {
    const data = useStaticQuery(graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `)

    return (
        <div>
            <Helmet>
                <title>JCC Technologies</title>
                <meta name="description" content="JCC Technologies is a company based in Herefordshire aiming primarily to deliver technological advances to farmers and calf rearers."/>
                <link rel="icon" href={favicon} />
            </Helmet>
            <div className="bg-white">
                <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
                <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 overflow-hidden">
                    {children}
                </main>
                <Footer />
            </div>
        </div>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
