import PropTypes from "prop-types"
import React, { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'

import {
    MenuIcon,
    XIcon,
    PhoneIcon
} from '@heroicons/react/outline'
import { ChevronDownIcon } from '@heroicons/react/solid'

import JCCLogo from "/src/images/svg/SVGLogo.svg"
import HLLogo from "/src/images/svg/neue_CI_HL_Logo_Normal.svg"


const solutions = [
    {
        name: 'CalfExpert',
        description: "The new trendsetter among calf feeders.",
        href: '/products/calfexpert'
    },
    {
        name: 'MilkTaxi',
        description: "Innovative New Approaches to Bucket Feeding.",
        href: '/products/milktaxi'
    },
]


function Header({ siteTitle }) {
    return (
        <header>
            <Popover className="relative bg-white">
                <div className="flex justify-between items-center max-w-7xl mx-auto px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
                    <div className="flex justify-start lg:w-0 lg:flex-1">
                        <a href="/">
                            <span className="sr-only">JCC Technologies</span>
                            <img
                                src={JCCLogo}
                                className="h-12 w-auto sm:h-16"
                                alt="JCC Technologies Logo"
                            />
                        </a>
                    </div>
                    <div className="-mr-2 -my-2 md:hidden">
                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                            <span className="sr-only">Open menu</span>
                            <MenuIcon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                    </div>
                    <Popover.Group as="nav" className="hidden md:flex space-x-5 lg:space-x-10">
                        <a href="/about" className="text-base font-medium inline-flex items-center justify-center text-gray-500 hover:text-gray-900">
                            About
                        </a>
                        <Popover className="relative items-center justify-center">
                            {({ open }) => (
                                <>
                                    <Popover.Button
                                        className={classNames(
                                            open ? 'text-gray-900' : 'text-gray-500',
                                            'my-4 group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                                        )}
                                    >
                                        <span>Products</span>
                                        <ChevronDownIcon
                                            className={classNames(
                                                open ? 'text-gray-600' : 'text-gray-400',
                                                'ml-2 h-5 w-5 group-hover:text-gray-500'
                                            )}
                                            aria-hidden="true"
                                        />
                                    </Popover.Button>

                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-200"
                                        enterFrom="opacity-0 translate-y-1"
                                        enterTo="opacity-100 translate-y-0"
                                        leave="transition ease-in duration-150"
                                        leaveFrom="opacity-100 translate-y-0"
                                        leaveTo="opacity-0 translate-y-1"
                                    >
                                        <Popover.Panel className="absolute z-50 -ml-4 mt-3 transform w-screen max-w-md lg:max-w-2xl lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                                            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                                <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2">
                                                    {solutions.map((item) => (
                                                        <a
                                                            key={item.name}
                                                            href={item.href}
                                                            className="-m-3 p-3 flex items-start rounded-lg hover:bg-blue-50"
                                                        >
                                                            <div className="ml-4">
                                                                <p className="text-base font-medium text-gray-900">{item.name}</p>
                                                                <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                                                            </div>
                                                        </a>
                                                    ))}
                                                </div>
                                            </div>
                                        </Popover.Panel>
                                    </Transition>
                                </>
                            )}
                        </Popover>

                        <a
                          href="/contact"
                          className="text-base font-medium inline-flex items-center justify-center text-gray-500 hover:text-gray-900"
                        >
                            Contact
                        </a>

                        <a                           
                            href="tel:07974181595"
                            className="whitespace-nowrap bg-blue-100 border border-transparent rounded-md py-2 px-4 inline-flex items-center justify-center text-base font-medium text-blue-700 hover:bg-blue-200"
                        >
                            <PhoneIcon className="w-4 mr-1" /> 07974 181595
                        </a>
                    </Popover.Group>
                    <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                        <img
                            className="h-12"
                            src={HLLogo}
                            alt="Holm & Laue logo"
                        />
                    </div>
                </div>

                <Transition
                    as={Fragment}
                    enter="duration-200 ease-out"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="duration-100 ease-in"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <Popover.Panel
                        focus
                        className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
                    >
                        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                            <div className="pt-5 pb-6 px-5">
                                <div className="flex items-center justify-between">
                                    <div>
                                        <a href="/">
                                            <span className="sr-only">JCC Technologies</span>
                                            <img
                                                width={192}
                                                src={JCCLogo}
                                                className="h-16 w-auto sm:h-14"
                                                alt="JCC Technologies Logo"
                                            />
                                        </a>
                                    </div>
                                    <div className="md:flex items-center justify-end md:flex-1 lg:w-0">
                                        <img
                                            className="h-12"
                                            src={HLLogo}
                                            alt="Holm & Laue logo"
                                        />
                                    </div>
                                    <div className="-mr-2">
                                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                                            <span className="sr-only">Close menu</span>
                                            <XIcon className="h-6 w-6" aria-hidden="true" />
                                        </Popover.Button>
                                    </div>
                                </div>
                                <div className="mt-6">
                                    <nav className="grid grid-cols-1 gap-7">
                                        {solutions.map((item) => (
                                            <a
                                                key={item.name}
                                                href={item.href}
                                                className="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50"
                                            >
                                                <div className="ml-4 text-base font-medium text-gray-900">{item.name}</div>
                                            </a>
                                        ))}
                                        <a href="/about" className="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50">
                                            <div className="ml-4 text-base font-medium text-gray-900">About</div>
                                        </a>
                                    </nav>
                                </div>
                            </div>
                            <div className="pb-6 pt-2 px-5">
                                <div className="mt-6">
                                    <a
                                        href="/contact"
                                        className="w-full flex items-center justify-center bg-gradient-to-r from-brand-dark to-brand-light bg-origin-border px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white hover:from-blue-700 hover:to-light-blue-700"
                                    >
                                        Contact Us
                                    </a>
                                </div>
                            </div>
                        </div>
                    </Popover.Panel>
                </Transition>
            </Popover>
        </header>
    )
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header
